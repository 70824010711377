.accommodation-label {
  display: flex;
  align-items: center;
  border: 1px solid $athens-gray-10;
  background-color: $just-white;
  border-radius: 10px;
  margin-right: .5em;
  font-size: 10px;
  padding: 4px 4px;
  height: 22px;

  .boxed-icon {
    width: 16px;
    height: 16px;
    margin-right: .5em;

    i {
      font-size: 8px;
    }
  }
}