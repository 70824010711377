.detail-page-return {
  padding: 30px 0;

  .icon-arrow-left {
    .path1, .path2 {
      &:before {
        color: $blue-jeans;
      }
    }
  }
}

.background-detail-page {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  top: -30px;
  z-index: -1;

  svg {
    @include media-breakpoint-up(lg) {
      width: 100vw;
    }
  }
}

.background-detail-price-list {
  width: 100vw;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  top: -60px;
  left: calc(-1 * (100vw - 100%) / 2);

  svg {
    @include media-breakpoint-up(lg) {
      width: 100vw;
    }
  }
}

.background-detail-accommodations {
  width: 100vw;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  top: -60px;
  left: calc(-1 * (100vw - 100%) / 2);
  
  svg {
    @include media-breakpoint-up(lg) {
      width: 100vw;
    }
  }
}

.detail-page-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .boxed-icon {
    margin-right: 1em;
    height: 60px;
    width: 60px;
    border-radius: 16px;
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    i {
      font-size: 30px;
    }
  }

  h1 {
    font-size: 35px;
    line-height: 35px;
  }

  .sub-title {
    font-size: 24px;
    margin-top: 8px;
    display: block;

    a {
      font-size: 24px;
    }
  }

  .detail-page-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: flex-end;
    }

    button {
      width: 100%;
      margin-top: 16px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      &:nth-of-type(2) {
        @include media-breakpoint-up(lg) {
          margin-left: 16px;
        }
      }
    }
  }
}

.domain-description {
  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
  }

  ul {
    li {
      padding-left: 20px;
      position: relative;

      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: calc(16px - 1.5px);
        display: block;
        height: 3px;
        width: 10px;
        border-radius: 3px;
        margin-right: 8px;
        background-color: $space-cadet-25;
      }

      ol {
        li {
          padding-left: 0;
          
          &:before {
            content: none;
          }
        }
      }
    }
  }

  .description-content {
    overflow: hidden;
    transition: all 0.5s ease;
    position: relative;
  }

  .description-wrapper {
      position: relative;
  }

  .description-wrapper:not(.no-collapse) .description-content {
      max-height: var(--max-height, none);
      overflow: hidden;
      transition: max-height 0.3s ease;
  }

  .description-wrapper:not(.no-collapse).expanded .description-content {
      max-height: var(--full-height);
  }

  .description-gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 150px;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      pointer-events: none;
  }

  .description-wrapper:not(.no-collapse):not(.expanded) .description-gradient {
      display: block;
  }

  .read-more-button {
      align-items: center;
      gap: 8px;
      background: none;
      border: none;
      margin: 0 auto;
      cursor: pointer;
      color: #009EF7;
  }

  .description-wrapper:not(.no-collapse) .read-more-button {
      display: flex;
  }

  .read-more-button img {
      transition: transform 0.3s ease;
  }

  .description-wrapper.expanded .read-more-button img {
      transform: rotate(180deg);
  }

  .read-less-text {
      display: none;
  }
}

.description-wrapper.expanded {
  .read-more-text {
    display: none;
  }

  .read-less-text {
    display: inline;
  }

  .icon-caret-down {
    transform: rotate(180deg);
  }

  .description-gradient {
    display: none;
  }
}

html {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
  scroll-duration: 800ms;
}
