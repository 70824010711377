.accommodation-mini-group {
  background: $just-white;
  box-shadow: 0px 8px 48px rgba(26, 30, 64, 0.12);
  border-radius: 24px;
  overflow: hidden;
  padding: 20px;
}

.accommodation-mini {
  border-radius: 24px;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0px 8px 48px rgba(26, 30, 64, 0.12);
  position: relative;
  background: $just-white;
  container-type: inline-size;

  .badge {
    width: 100%;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $just-white;
    padding: 20px 0;
  }

  .head-title {
    display: flex;
    width: 90%;
    margin-right: 10%;

    a {
      line-height: 32px;
      text-decoration: none;
      color: $text-color;
      font-size: calc(min(6cqw, 1.5rem));

      @-moz-document url-prefix() {
        font-size: 1rem;
      }

      &:hover {
        color: $blue-jeans;
      }
    }
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  button {
    background: transparent;
    border: 0;
    color: $coral;
    cursor: pointer;
    padding: .5em;
    position: absolute;
    top: 20px;
    right: 20px;

    i {
      transition: transform .125s ease-in;
    }
  }

  .prices-group {
    display: flex;
  }

  .prices {
    text-align: center;

    &:nth-of-type(2) {
      margin-left: 16px;
    }

    small {
      display: block;
      text-transform: uppercase;
      color: $blue-jeans-125;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .specifications {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .spec-group {
      padding: 0 16px;
      position: relative;
      flex-grow: 1;
      text-align: center;

      i {
        font-size: 16px;
        color: $blue-jeans;
        margin-right: 8px;
      }

      &:before {
        content: ' ';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $blue-jeans-50;
        position: absolute;
        left: -2px;
        top: 14px;
      }

      &:last-of-type {
        text-align: right;
      }

      &:first-of-type {
        text-align: left;
        &:before {
          display: none;
        }
      }
    }
  }

}

.mini-sub-items {
  overflow: hidden;
}

.accommodation-mini-sub {
  margin-bottom: 10px;
  opacity: 1;
  padding-top: 16px;
  border-top: 1px solid $space-cadet-25;

  &:first-of-type{
    padding-top: 0;
    border-top: 0;
  }

  &:nth-of-type(1) {
    transition: background-color 0.125s ease-in, opacity .5s ease-in .125s;
  }

  &:nth-of-type(2) {
    transition: background-color 0.125s ease-in, opacity .5s ease-in .250s;
  }

  &:nth-of-type(3) {
    transition: background-color 0.125s ease-in, opacity .5s ease-in .375s;
  }

  &:nth-of-type(4) {
    transition: background-color 0.125s ease-in, opacity .5s ease-in .500s;
  }

  &:nth-of-type(5) {
    transition: background-color 0.125s ease-in, opacity .5s ease-in .625s;
  }

  &.hidden {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.accommodation-mini + .accommodation-mini {
  border-top: 1px solid $space-cadet-10;
}

.linked-accommodation-component {
  padding: 16px;
  border-radius: 24px;
  background-color: $blue-jeans-10;
  position: relative;

  h3 {
    width: 100%;
    text-align: center;
    color: $blue-jeans-125;
    margin: .75em 0;
    font-size: calc(max(16px, 2vw));
  }

  .linked-accommodation-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 3;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .linked-accommodation {
      flex-grow: 1;

      @include media-breakpoint-up(md) {
        margin: 0 8px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 33%;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}